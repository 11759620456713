import { ToysTwoTone } from '@material-ui/icons';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import React, { Component } from 'react';
import { emptyObject } from "../../controller/utils";

export default class MyPdfCartas extends Component {
    constructor(props) {
        super(props);
    }

    styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            backgroundColor: '#fff'
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1
        },
        backgroundImg: {
            position: 'absolute',
            left: 0,
            top: 0
        },
        centerImage: {
            alignItems: "center",
            flexGrow: 1
        },
        image: {
            padding: 0,
            width: '105%',
            height: '102%',
            position: 'absolute',
            left: 0
        },
        imagePosition: (left, right, top, size, size2) => {
            return {
                left: left ? `${left}` : 'auto',
                right: right ? `${right}` : 'auto',
                top: top ? `${top}` : 'auto', // 175
                width: size ? size : '100%',
                height: size2 ? size2 : '100%',
                position: 'absolute'
            };
        },
        fontText: (left, right, top, size) => {
            return {
                left: left ? `${left}` : 'auto',
                right: right ? `${right}` : 'auto',
                top: top ? `${top}` : 'auto', // 175
                fontSize: size ? size : 9,
                position: 'absolute',
                fontWeight: 'bold'
            };
        },

    });
    fontSize(cadena) {
        return cadena.length > 70 ? 5
            : cadena.length > 60 ? 6 : cadena.length > 50 ? 7 : 8;
    };
    fontSizeM(cadena) {
        return cadena.length > 60 ? 4 : cadena.length > 50 ? 5
            : cadena.length > 40 ? 6 : cadena.length > 30 ? 7 : 8;
    };

    fontSizeA(cadena) {
        return cadena.length > 55 ? 2 : cadena.length > 41 ? 3 : cadena.length > 32 ? 4 : cadena.length > 25 ? 5
            : cadena.length > 21 ? 6 : cadena.length > 19 ? 7 : 8;
    };

    render() {
        const { data, id } = this.props;
        console.log(data);
        var nuevo = "Le invitamos a retomar la postulación enviado la documentación corregida por medio de nuestra Sucursal Virtual  con el mismo número de registro que se envió al correo electrónico registrado al iniciar su proceso de afiliación. Recuerde que una vez enviados los documentos, la afiliación pasa nuevamente a estar en proceso de validación por parte del área correspondiente la cual le notificará al correo electrónico si la afiliación es aprobada o no."
        

        return (
            <React.Fragment>
                <Document>
                    <Page size="A4" style={this.styles.page}>
                        <View style={this.styles.section}>
                            <Image style={this.styles.image} src="/fondoCartas.jpg" />
                            {/* COMPANY DATA FORM*/}
                            <Text style={this.styles.fontText(45, 45, 160, 9)}>{nuevo}</Text>
                        </View>
                    </Page>
                </Document>
            </React.Fragment>
        );
    }
}
