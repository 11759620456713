import {
  CATALOG_STATES,
  CLOSE_MODAL_ANEXOS,
  CLOSE_MODALES,
  ERROR_GENERAL,
  GESTIONA_MODAL_TRY_CATCH,
  INIT_BANDEJA_ADMINISTRADOR,
  INIT_FORM_FROM_REVALIDACION,
  LOAD_DOCUMENT_SUCCESS,
  MESSAGE_ALERT_SEND,
  MESSAGE_CONFIRMATION_SEND,
  MESSAGES_ALERT_PARAMETRIZADOS,
  OCULTAR_DE_ANULACIONES,
  OCULTAR_DEVOLUCIONES,
  OPEN_ANEXOS_FROM_TABLE,
  OPEN_FORM_BANDEJA,
  OPEN_HISTORIAL_BANDEJA,
  OPEN_MODAL_ANULATION_TABLE,
  OPEN_MODAL_ASIGNA_ASESOR_TABLE,
  OPEN_MODAL_CHARGED,
  OPEN_MODAL_DEVOLUTION_TABLE,
  RETURN_HOME_BANDEJA,
  SAVE_DATA_TABLE_ANULA_DEVOL,
  SAVED_INFO_USER_BANDEJA,
  SET_DATA_PAGINAR_REG_VIVIENDA,
  TYPE_USER_BANDEJA_VIVIENDA,
  VISUALIZE_ANEXOS,
  VISUALIZE_ANEXOS_FROM_TABLE,
} from "./constants";

export const initialState = {
  userInfo: false,
  sessionValidated: false,
  dataRegVivienda: [],
  consultaPaginada: false,
  openModalCharged: false,
  openMondalTryCatch: false,
  errorTryCatch: false,
  msgParametrizados: MESSAGES_ALERT_PARAMETRIZADOS,
  messageAlert: false,
  idFormulario: false,
  openFormBandeja: false,
  openSeeAnexos: false,
  dataAnexostable: false,
  ocultaDevoluciones: false,
  nameButtonDev: "Devolver",
  typeUserBandeja: false,
  openAnexosTable: false,
  openAnulationTable: false,
  openAsignaAsesorTable: false,
  openDevolutionTable: false,
  messageConfirmation: false,
  dynamicOptions: {
    states: CATALOG_STATES
  }
};

const reducer = (state = initialState, action) => {
  const newState = { ...state };
  const { payload, type, value } = action;

  switch (type) {
    case SAVED_INFO_USER_BANDEJA:
      newState.userInfo = payload;
      newState.sessionValidated = true;
      return { ...newState };

    case SET_DATA_PAGINAR_REG_VIVIENDA:
      if (payload.esPrimeraPagina) {
        newState.dataRegVivienda = payload.dataRegVivienda;
      } else {
        newState.dataRegVivienda = payload.dataPaginada;
      }
      newState.consultaPaginada = true;
      return { ...newState };

    case ERROR_GENERAL:
      newState.openModalCharged = false;
      newState.errorTryCatch = payload;
      newState.openMondalTryCatch = true;
      return { ...newState };

    case MESSAGE_ALERT_SEND:
      newState.messageAlert = payload;
      return { ...newState };

    case INIT_BANDEJA_ADMINISTRADOR:
      newState.bandejaAdmin = true;
      return { ...newState };

    case RETURN_HOME_BANDEJA:
      newState.openFormBandeja = false;
      newState.idFormulario = false;
      newState.messageConfirmation = false;
      newState.messageAlert = false;
      newState.messageHistory = false;
      newState.openSeeAnexos = false;
      newState.openInformes = false;
      newState.formRevalidacion = false;
      newState.openAnulationTable = false;
      newState.openDevolutionTable = false;
      newState.openAsignaAsesorTable = false;

      return { ...newState };

    case CLOSE_MODALES:
      // newState.dataModalDatos = false;
      newState.messageAlert = false;
      newState.messageConfirmation = false;
      newState.messageHistory = false;
      newState.historyBandeja = false;

      return { ...newState };

    case GESTIONA_MODAL_TRY_CATCH:
      newState.openMondalTryCatch = false;
      return { ...newState };

    case OPEN_FORM_BANDEJA:
      newState.openFormBandeja = payload.state;
      newState.idFormulario = payload.idForm;
      return { ...newState };

    case VISUALIZE_ANEXOS:
      newState.openSeeAnexos = value.row;
      return { ...newState };

    case VISUALIZE_ANEXOS_FROM_TABLE:
      if (value.destroy)
        newState.openAnexosTable = false;
      else
        if (value.open)
          newState.dataAnexostable = value.row;
        else
          newState.dataAnexostable = false;
      return { ...newState };

    case OPEN_ANEXOS_FROM_TABLE:
      newState.openAnexosTable = payload;
      return { ...newState };

    case OPEN_MODAL_ANULATION_TABLE:
      newState.openAnulationTable = payload ? payload : false;
      return { ...newState };

    case OPEN_MODAL_ASIGNA_ASESOR_TABLE:
      newState.openAsignaAsesorTable = payload ? payload : false;
      return { ...newState };

    case OPEN_MODAL_DEVOLUTION_TABLE:
      newState.openDevolutionTable = payload ? payload : false;
      return { ...newState };

    case CLOSE_MODAL_ANEXOS:
      newState.openSeeAnexos = false;
      return { ...newState };

    case SAVE_DATA_TABLE_ANULA_DEVOL:
      newState.dataAnulaDevol = false;
      return { ...newState };

    case OPEN_MODAL_CHARGED:
      newState.openModalCharged = payload;
      return { ...newState }

    case TYPE_USER_BANDEJA_VIVIENDA:
      newState.typeUserBandeja = payload;
      return { ...newState }

    case MESSAGE_CONFIRMATION_SEND:
      newState.messageConfirmation = payload;
      return { ...newState };

    case INIT_FORM_FROM_REVALIDACION:
      newState.formRevalidacion = payload;
      return { ...newState };

    case OCULTAR_DEVOLUCIONES:
      newState.ocultaDevoluciones = true;
      newState.nameButtonDev = "Anular";
      return { ...newState };

    case OCULTAR_DE_ANULACIONES:
      newState.ocultaDevoluciones = false;
      newState.nameButtonDev = "Devolver";
      return { ...newState };

    case OPEN_HISTORIAL_BANDEJA:
      newState.messageHistory = true;
      newState.historyBandeja = payload;
      return { ...newState };


    case LOAD_DOCUMENT_SUCCESS:
      newState.dynamicOptions = payload;
      return { ...newState };


    default:
      return {
        ...newState,
      };
  }
};

export default reducer;
