import { getFormValues, } from 'redux-form' // ES6;
import { ANULACION_FORM_NAME, ASIGNACION_FORM_NAME, DEVOLUCIONES_FORM_NAME } from './constants';

export const getFormValuesDevolucion = (state) => getFormValues(DEVOLUCIONES_FORM_NAME)(state);
export const getFormValuesAnulacion = (state) => getFormValues(ANULACION_FORM_NAME)(state);
export const getFormValuesAsignacion = (state) => getFormValues(ASIGNACION_FORM_NAME)(state);
export const getSessionValidated = (state) => state.localReducer.sessionValidated;
export const getUserInfo = (state) => state.localReducer.userInfo;
export const getOpenModalCharged = (state) => state.localReducer.openModalCharged;
export const getSnackBar = (state) => state.localReducer.showSnackBar;
export const getErrorTryCatch = (state) => state.localReducer.errorTryCatch;
export const getOpenMondalTryCatch = (state) => state.localReducer.openMondalTryCatch;
export const getDataRegVivienda = (state) => state.localReducer.dataRegVivienda;
export const getConsultaPaginada = (state) => state.localReducer.consultaPaginada;
export const getMessageAlert = (state) => state.localReducer.messageAlert;
export const getMsgParametrizados = (state) => state.localReducer.msgParametrizados;
export const getOpenFormBandeja = (state) => state.localReducer.openFormBandeja;
export const getIdFormulario = (state) => state.localReducer.idFormulario;
export const getOpenSeeAnexos = (state) => state.localReducer.openSeeAnexos;
export const getOcultaDevoluciones = (state) => state.localReducer.ocultaDevoluciones;
export const getNameButtonDev = (state) => state.localReducer.nameButtonDev;
export const getMessageHistory = (state) => state.localReducer.messageHistory;
export const getHistoryBandeja = (state) => state.localReducer.historyBandeja;
export const getSuc_id = (state) => state.localReducer.suc_id;
export const getUserTypeBandeja = (state) => state.localReducer.typeUserBandeja;
export const getDynamicOptions = (state) => state.localReducer.dynamicOptions;
export const getOpenAnexosTable = (state) => state.localReducer.openAnexosTable;
export const getDataAnexostable = (state) => state.localReducer.dataAnexostable;
export const getOpenDevolutionTable = (state) => state.localReducer.openDevolutionTable;
export const getOpenAnulationTable = (state) => state.localReducer.openAnulationTable;
export const getOpenAsignaAsesorTable = (state) => state.localReducer.openAsignaAsesorTable;
export const getMessageConfirmation = (state) => state.localReducer.messageConfirmation;
export const getDataAnulaDevol = (state) => state.localReducer.dataAnulaDevol;
export const getFormRevalidacion = (state) => state.localReducer.formRevalidacion;
export const getBandejaAdmin = (state) => state.localReducer.bandejaAdmin;