import { createStructuredSelector } from 'reselect';
import { getFormValues, } from 'redux-form' // ES6;

export const getCompanyFormValues = (state) => getFormValues("companyContactForm")(state);
export const getPeopleFormValues = (state) => getFormValues("peopleContactForm")(state);

export const getbase64Add = (state) => state.localReducer.base64Add;
export const getAuthForm = (state) => state.localReducer.authForm;
export const getSelectedForm = (state) => state.localReducer.selectedForm;
export const getSelectedCompanyForm = (state) => state.localReducer.selectedCompanyForm;
export const getDataDisable = (state) => state.localReducer.dataDisable;

export const getSelectedType = (state) => state.localReducer.selectedType;
export const getSelectedTab = (state) => state.localReducer.tabSelected;
export const getMessage = (state) => state.localReducer.message;
export const getContactoAdd = (state) => state.localReducer.contactoAdd;
export const getAuthError = (state) => state.localReducer.error;
export const getReloadPage = (state) => state.localReducer.reloadPage;
export const getLogSuccess = (state) => state.localReducer.logSuccess;
export const getFormInitiated = (state) => state.localReducer.formInitiated;
export const getValidForm = (state) => state.localReducer.validForm;

export const getFormFiles = (state) => state.localReducer.formFiles;
export const getDynamicOptions = (state) => state.localReducer.dynamicOptions;
export const getDynamicDisable = (state) => state.localReducer.dynamicDisable;
export const getHiddenDisable = (state) => state.localReducer.hiddenDisable;
export const getCharged = (state) => state.localReducer.charged;
export const getSaveBd = (state) => state.localReducer.saveBd;
export const getCatalogosCargados = (state) => state.localReducer.catalogos;
export const getCatalogosCargos = (state) => state.localReducer.cargos;
export const getDataAuth = (state) => state.localReducer.dataAuth;
export const getConsultaValidador = (state) => state.localReducer.consultaValidador;
export const getChangeType= (state) => state.localReducer.typeForm;
export const getValidar= (state) => state.localReducer.validar;
export const getDataSelected = (state) => state.localReducer.dataSelected;

export const authSelector = createStructuredSelector({
    authForm: getAuthForm,
    selectedFotm: getSelectedForm,
    tabSelected: getSelectedTab,
    message: getMessage,
    error: getAuthError,
    logSuccess: getLogSuccess,
});