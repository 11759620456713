import { takeLatest, put, call, select } from "redux-saga/effects";
import axios from "axios";

import {
  COMPANY_KEY,
  CREATED_COMPANY_OK,
  CREATED_PEOPLE_OK,
  CREATE_COMFE_TICKET,
  INIT_USER_DATA,
  SIGN_USER,
  UPLOAD_FILE,
  REMOVE_FILE,
  LOAD_CATALOG,
  REDUX_CHANGE,
  SAVE_BD,
  BASE_64,
  SELECT_FORM_FIELD,
  VALIDATE_FORM,
  SELECT_TAB,
  HEADERS_WS,
  OCULTAR_CAMPOS_FORM,
  MOSTRAR_CAMPOS_FORM,
  MOSTRAR_CAMPOS_FORM_ARRAY,
  MOSTRAR_CAMPOS_FORM_LISTA,
  peopleContactForm2,
  generatePeopleSubForm,
  PEOPLE_FORM2,
  PEOPLE_FORM,
  MOSTRAR_CAMPOS_FORM_VAlIDADOR,
  PARAMETRIZACION_DERECHO,
  REQUIRED_COMPANY,
  REQUIRED_PERSONAL,
  REQUIRED_PERSONAL_COMPANY,
  REQUIRED_SOLICITUD,
  INIT_INFO_SOLICITUD,
  MOSTRAR_CAMPOS_FORM_VAlIDADOR_SELECTED,
  MOSTRAR_CAMPOS_FORM_SELECTED,
} from "./constants";

import {
  authSuccess,
  authError,
  initUserSuccess,
  initContactSuccess,
  initContactError,
  uploadReducer,
  loadCatalogSuccess,
  loadDocumentSuccess,
  deleteUpload,
  chargedCatalog,
  contactoAdd,
  guardarBD,
  selectedType,
  reloadPage,
  crearBase64,
  cargarCargo,
  cargarCatalogos,
  dataAuth,
  callDinamicDisable,
  base64Add,
  base64Remove,
  callDisabled,
  saveConsultaValidador,
  disablePeopleForm,
  changeType,
  dataMotivoSelected,
} from "./actions";

import { getbase64Add, getCatalogosCargados, getCatalogosCargos, getDataDisable, getDataAuth, getSelectedType, getConsultaValidador, getValidForm, getDataSelected } from "./selectors"
import { firebaseDatabase, firebaseStorage } from "../../controller/firebase";
import {
  emptyObject,
  generateUUID,
  getAsyncCatalog,
  getAsyncCollection,
  sleep,
} from "../../controller/utils";

import { reset, change, initialize } from "redux-form";
import { constant } from "lodash-es";
import { REDUX_BLUR } from "../vivienda/constants";

function* llenarForm(action) {

  const value = yield select(getDataAuth);

  var tipoP = ""
  var tipoE = ""
  tipoP = emptyObject(value['https://jalexanderv.com/auth/tipo']);
  tipoE = emptyObject(value['https://empresas.comfenalcovalle.com.co/auth/tipo']);
  if (value) {
    if (tipoE) {
      const companyContactForm = {
        companydataform: {
          socialName: emptyObject(value['https://empresas.comfenalcovalle.com.co/auth/razon']),
          companyDocument: emptyObject(value['https://empresas.comfenalcovalle.com.co/auth/rest_id']),
          documentCompanyType: emptyObject(value['https://empresas.comfenalcovalle.com.co/auth/tipo_identificacion']),
          companyEmail: emptyObject(value['https://empresas.comfenalcovalle.com.co/auth/rest_email']),
        }
      }
      const res = yield put(initialize("companyContactForm", companyContactForm));

      if (res) {
        const data = [
          'socialName',
          'documentCompanyType',
          'companyDocument',
          'companyEmail',
        ];

        yield put(callDinamicDisable({ data: data, state: true }))

      }


    } else if (tipoP) {
      const peopleContactForm = {
        informacionpersonal: {
          primerNombre: emptyObject(value["https://jalexanderv.com/auth/rest_name"]),
          segundoNombre: emptyObject(value["https://jalexanderv.com/auth/rest_mname"]),
          primerApellido: emptyObject(value["https://jalexanderv.com/auth/rest_lname"]),
          abreviatura: emptyObject(value["https://jalexanderv.com/auth/tipo_identificacion"]),
          identificacion: emptyObject(value["https://jalexanderv.com/auth/rest_id"]),
          email: emptyObject(value["https://jalexanderv.com/auth/email"]),
          celular: emptyObject(value["https://jalexanderv.com/auth/rest_celular"]),
          idMuniResidencia: emptyObject(value["https://jalexanderv.com/auth/idMuniResidencia"]),
          genero: emptyObject(value["https://jalexanderv.com/auth/sexo"]),
          nacimiento: emptyObject(value["https://jalexanderv.com/auth/fecha_nacimiento"]),
          autenticado: "true",
        },
      };

      const res = yield put(initialize("peopleContactForm", peopleContactForm));
      const selectType = yield select(getSelectedType)
      if (res && selectType.fieldValue === "people") {
        const data = [
          'primerNombre',
          'segundoNombre',
          'primerApellido',
          'abreviatura',
          'identificacion',
          'email',
          'celular',
          'genero',
          'nacimiento',
          'autenticado',
        ];
        var aux = []
        data.forEach(element => {
          if (peopleContactForm.informacionpersonal[element]) {
            aux.push(element);
          }
        });

        yield put(callDinamicDisable({ data: aux, state: true }))

      }
    }

    yield put(initUserSuccess());
  }

}
function* inituserData(action) {
  const { value } = action;
  yield put(dataAuth(value));
  let userData = {};
  var tipoP = ""
  var tipoE = ""
  tipoE = emptyObject(value['https://empresas.comfenalcovalle.com.co/auth/tipo']);
  if (value) {
    if (tipoE) {
      const companyContactForm = {
        companydataform: {
          //abreviatura:emptyObject('https://empresas.comfenalcovalle.com.co/auth/abreviatura'),
          //email:emptyObject('https://empresas.comfenalcovalle.com.co/auth/email'),
          socialName: emptyObject(value['https://empresas.comfenalcovalle.com.co/auth/razon']),
          companyDocument: emptyObject(value['https://empresas.comfenalcovalle.com.co/auth/rest_id']),
          documentCompanyType: emptyObject(value['https://empresas.comfenalcovalle.com.co/auth/tipo_identificacion']),
          companyEmail: emptyObject(value['https://empresas.comfenalcovalle.com.co/auth/rest_email']),
          autenticado: "true",
        }
      }
      const res = yield put(initialize("companyContactForm", companyContactForm));

      if (res) {
        const data = [
          'socialName',
          'documentCompanyType',
          'companyDocument',
          'companyEmail',
        ];

        yield put(callDinamicDisable({ data: data, state: true }))

      }
    } else {
      const peopleContactForm = {
        informacionpersonal: {
          primerNombre: emptyObject(value["https://jalexanderv.com/auth/rest_name"]),
          segundoNombre: emptyObject(value["https://jalexanderv.com/auth/rest_mname"]),
          primerApellido: emptyObject(value["https://jalexanderv.com/auth/rest_lname"]),
          abreviatura: emptyObject(value["https://jalexanderv.com/auth/tipo_identificacion"]),
          identificacion: emptyObject(value["https://jalexanderv.com/auth/rest_id"]),
          email: emptyObject(value["https://jalexanderv.com/auth/email"]),
          celular: emptyObject(value["https://jalexanderv.com/auth/rest_celular"]),
          idMuniResidencia: emptyObject(value["https://jalexanderv.com/auth/idMuniResidencia"]),
          genero: emptyObject(value["https://jalexanderv.com/auth/sexo"]),
          nacimiento: emptyObject(value["https://jalexanderv.com/auth/fecha_nacimiento"]),
          autenticado: "true",
        },
      };


      const res = yield put(initialize("peopleContactForm", peopleContactForm));
      const selectType = yield select(getSelectedType)
      if (res && selectType.fieldValue === "people") {
        const data = [
          'primerNombre',
          'segundoNombre',
          'primerApellido',
          'abreviatura',
          'identificacion',
          'email',
          'celular',
          'genero',
          'nacimiento',
          'autenticado',
        ];
        var aux = []
        data.forEach(element => {
          if (peopleContactForm.informacionpersonal[element]) {
            aux.push(element);
          }
        });

        yield put(callDinamicDisable({ data: aux, state: true }))

      }
    }

    yield put(initUserSuccess());
  }


}

function* signUser(action) {
  const { value } = action;
  const { body, type } = value ? value : {};

  const id = generateUUID();

  const createdMessage =
    type === COMPANY_KEY ? CREATED_COMPANY_OK : CREATED_PEOPLE_OK;

  try {
    const response = yield firebaseDatabase.collection(type).doc(id).set(body);
    yield put(authSuccess({ message: createdMessage }));
  } catch (error) {
    yield put(authError(error));
  }
}


function* createTicket(action) {

  const valid = yield validateForm(action.value);
  const urlConfenalco = process.env.REACT_APP_URL_COMFENALCO_ENDPOINT;
  if (valid.valid) {
    console.time("inicio");
    const { value } = action;

    let informacionpersonal = {
      abreviatura: '',
      identificacion: '',
      primerNombre: '',
      primerApellido: '',
      email: '',
      celular: '',
      genero: '',
      nacimiento: '',
      idMuniResidencia: '',
      segundoNombre: '',
      phone: '',

    };
    let companyrequestform = {};
    let companydataform = {};
    let informacionsolicitud = {};
    var formData = {};
    var d = new Date();
    let dateTimes = "";
    dateTimes = d//.toLocaleString();
    const base64 = [];

    let aux = [];

    try {
      if (value && value.body) {
        const getValidador = yield select(getConsultaValidador);
        if (getValidador.data?.abreviatura?.length > 0) {
          informacionpersonal = getValidador.data;
          if (getValidador.faltan.length > 0) {
            getValidador.faltan.forEach(element => {
              informacionpersonal[element] = emptyObject(value.body.informacionpersonal[element]);
            });
          }
        } else {
          informacionpersonal = {
            abreviatura: value.body.informacionpersonal.abreviatura,
            identificacion: value.body.informacionpersonal.identificacion,
            primerNombre: value.body.informacionpersonal.primerNombre,
            primerApellido: value.body.informacionpersonal.primerApellido,
            email: value.body.informacionpersonal.email,
            celular: value.body.informacionpersonal.celular,
            genero: value.body.informacionpersonal.genero,
            nacimiento: value.body.informacionpersonal.nacimiento,
            idMuniResidencia: value.body.informacionpersonal.idMuniResidencia,
          };
          if (value.body.informacionpersonal.segundoNombre) {
            informacionpersonal.segundoNombre =
              value.body.informacionpersonal.segundoNombre;
          } else {
            informacionpersonal.segundoNombre = "";
          }
          if (value.body.informacionpersonal.phone) {
            informacionpersonal.phone = value.body.informacionpersonal.phone;
          } else {
            informacionpersonal.phone = "";
          }
        }

        if (value.body.informacionpersonal.autenticado) {
          informacionpersonal.autenticado = "true";
        } else {
          informacionpersonal.autenticado = "false";
        }
        if (value.type === "company") {
          const cargo = yield select(getCatalogosCargos);
          var cargos = "";
          cargo.forEach(element => {
            if (element.label === value.body.informacionpersonal.cargo) {
              cargos = element.value;
              return;
            }
          });
          informacionpersonal.cargo = cargos;
          companydataform = value.body.companydataform;
          companydataform.companyCanal = "Formulario";
          if (value.body.companydataform.companyPhone) {
            companydataform.companyPhone =
              value.body.companydataform.companyPhone;
          } else {
            companydataform.companyPhone = "";
          }
          var tempMotivo = value.body.companyrequestform.goal.split(":");
          var motivo = tempMotivo[0].split(";");

          companyrequestform = {
            related: value.body.companyrequestform.related,
            site: value.body.companyrequestform.site,
            requestText: value.body.companyrequestform.requestText,
            requesType: value.body.companyrequestform.requesType,
            habeasData: value.body.companyrequestform.habeasData,
            mainCauseServiceIssueCategory: motivo[0],
            mainIncidentServiceIssueCategory: motivo[1],
            mainObjectPartServiceIssueCategory: motivo[2],
            serviceIssueCategoryID: motivo[3],
          };
        }

        if (value.type === "people") {
          var tempMotivo = value.body.informacionsolicitud.goal.split(":");
          var motivo = tempMotivo[0].split(";");

          informacionsolicitud = {
            related: value.body.informacionsolicitud.related,
            site: value.body.informacionsolicitud.site,
            requestText: value.body.informacionsolicitud.requestText,
            requesType: value.body.informacionsolicitud.requesType,
            habeasData: value.body.informacionsolicitud.habeasData,
            mainCauseServiceIssueCategory: motivo[0],
            mainIncidentServiceIssueCategory: motivo[1],
            mainObjectPartServiceIssueCategory: motivo[2],
            serviceIssueCategoryID: motivo[3],
          };
          formData = {
            datos: {
              informacionpersonal,
              informacionsolicitud,
            },
            tipo: value.type,
            dateTime: dateTimes,
          };
        } else {
          formData = {
            datos: {
              informacionpersonal,
              companyrequestform,
              companydataform,
            },
            tipo: value.type,
            dateTime: dateTimes,
          };
        }

        const refForm = yield firebaseDatabase.collection("contactenos").doc();

        var ref = "";
        const formPromise = refForm;
        var requestOptions = {};

        const urlConfenalco = process.env.REACT_APP_URL_COMFENALCO_ENDPOINT;

        const refeFiles = []
        const files = new Array;

        if (value.formFiles) {

          const bases = yield select(getbase64Add)
          var base = [];
          bases.forEach(element => {
            base.push(element.doc);
          });

          ref = yield firebaseDatabase
            .collection("contactenos")
            .doc(`${formPromise.id}`);

          yield value.formFiles.map(fileData => {
            const fireStorageRef = firebaseStorage.ref();
            const fileReference = fireStorageRef.child(`${formPromise.id}/${fileData.formName}/${fileData.fieldName}/${fileData.fileName}`);
            fileReference.put(fileData.file);
            let anexo = {
              name: fileData.fieldName,
              charged: 'true',
              refFile: `${formPromise.id}/${fileData.formName}/${fileData.fieldName}/${fileData.fileName}`
            }

            refeFiles.push(anexo);
          });


          formData.refFiles = {
            refStorage: refeFiles,
          };
          refForm.set(formData);


          yield put(reloadPage({ reload: false }));
          yield put(
            contactoAdd({ message: "Estamos registrando tu petición, por favor permanece en línea.", error: false })
          );
          console.timeEnd("inicio");
          yield enviarTicket({
            urlConfenalco: urlConfenalco,
            value: formData,
            ref: refForm,
            base,
          });




        } else {
          refForm.set(formData);
          yield put(reloadPage({ reload: false }));
          yield put(
            contactoAdd({ message: "Estamos registrando su petición, por favor permanece en línea.", error: false })
          );
          console.timeEnd("inicio");
          yield enviarTicket({
            urlConfenalco: urlConfenalco,
            value: formData,
            ref: refForm,
            base64: [{}],
          });


        }
      } else {
        // yield put(reset("companyContactForm"));
        // yield put(reset("peopleContactForm"));
        yield put(
          initContactError(
            "Debes diligenciar todos los campos marcados con asterisco (*)"
          )
        );
      }
    } catch (e) {

      console.error("Excetion: " + e.name + ": " + e.message);
    }
  } else {
    yield put(
      initContactError(
      )
    );
    yield put(reloadPage({ reload: true }));
    yield put(
      contactoAdd({
        message: !valid.messageIndicativo ? "Debes diligenciar todos los campos marcados con asterisco (*)"
          : `Debes diligenciar todos los campos correctamente: ${valid.messageIndicativo}`,
        error: true,
      })
    );
  }
}

function* enviarTicket(action) {
  console.time("envio");
  const { base, urlConfenalco, value, ref } = action;

  let informacionpersonal = {};
  let informacionsolicitud = {};
  let companyrequestForm = {};
  let companydataform = {};

  if (value) {
    informacionpersonal = value.datos.informacionpersonal;
    if (value.tipo === "people") {
      informacionsolicitud = value.datos.informacionsolicitud;
    } else {
      companydataform = value.datos.companydataform;
      companyrequestForm = value.datos.companyrequestform;
    }
  }
  const catalogo = yield select(getCatalogosCargados);
  const tipoCliente = value.tipo === "people" ? "P" : "E";
  const request = yield value.tipo === "people"
    ? buildRequestPersona(
      informacionpersonal,
      informacionsolicitud,
      tipoCliente,
      base,
      catalogo.derecho
    )
    : buildRequestEmpresa(
      companydataform,
      informacionpersonal,
      companyrequestForm,
      tipoCliente,
      base,
      catalogo.derecho
    );

  const messageID =
    informacionpersonal.abreviatura + informacionpersonal.identificacion;
  const jsonData = {
    requestMessageIn: {
      header: {
        invokerDateTime: value.dateTime,
        moduleId: "CONTACTENOS",
        systemId: "PORTAL",
        messageId: messageID,
        logginData: {
          sourceSystemId: "",
          destinationSystemId: "",
        },
        destination: {
          namespace:
            "http://co/com/comfenalcovalle/esb/ws/InQueueCRMCreaTicketContactenos",
          name: "CRMCreaTicketContactenos",
          operation: "execute",
        },
        classification: {
          classification: "",
        },
      },
      body: {
        request,
      },
    },
  };

  const requestOptions = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
      Authorization: "Basic Rk9STVVMQVJJT1NXRUI6WWsyMTkwX3c=",
      accept: "application/json, text/plain, */*",
      "content-type": "application/json;charset=iso-8859-1",
      "Cache-Control": "no-cache",
      "cache-control": "no-cache",
      mode: "opaque ",
    },
    body: jsonData,
  };

  function buildRequestEmpresa(data, dataContacto, solicitud, type, base64, derecho) {
    const observacion = `\n Datos de contacto:\n Correo: ${dataContacto.email} \n Celular: ${dataContacto.celular} \n Telefono: ${dataContacto.phone}`;
    solicitud.requestText = solicitud.requestText.toUpperCase()
    derecho.forEach(element => {
      if (solicitud.requestText.indexOf(element.value) !== -1) {
        solicitud.requesType = "Z06";
        return;
      }
    });
    const request = {
      tipoCliente: type,
      tipoIdentificacion: data.documentCompanyType,
      numeroIdentificacion: data.companyDocument,
      correoElectronicoEmpresa: data.companyEmail,
      razonSocialEmpresa: data.socialName.toUpperCase(),
      telefonoCelularEmpresa: data.companyMobilePhone,
      telefonoFijoEmpresa: data.companyPhone,
      tamanioEmpresa: data.companySize,
      municipioDomicilioEmpresa: data.companyTown,
      tipoIdentificacionContacto: dataContacto.abreviatura,
      numeroIdentificacionContacto: dataContacto.identificacion,
      primerNombreContacto: dataContacto.primerNombre.toUpperCase(),
      segundoNombreContacto: dataContacto.segundoNombre.toUpperCase(),
      apellidosContacto: dataContacto.primerApellido.toUpperCase(),
      correoElectronicoContacto: dataContacto.email,
      telefonoCelularContacto: dataContacto.celular,
      telefonoFijoContacto: dataContacto.phone,
      cargoContacto: dataContacto.cargo,
      generoContacto: dataContacto.genero,
      fechaNacimientoContacto: dataContacto.nacimiento,
      autenticado: dataContacto.autenticado,
      canal: "113",
      processingTypeCode: solicitud.requesType,
      serviceIssueCategoryID: solicitud.serviceIssueCategoryID,
      mainIncidentServiceIssueCategory:
        solicitud.mainIncidentServiceIssueCategory,
      mainObjectPartServiceIssueCategory:
        solicitud.mainObjectPartServiceIssueCategory,
      mainCauseServiceIssueCategory: solicitud.mainCauseServiceIssueCategory,
      organisationalCentreID: solicitud.site,
      text: `${solicitud.requestText} ${observacion}`,
      documentos: base64,
    };

    return request;
  }

  function buildRequestPersona(data, solicitud, type, base64, derecho) {
    const observacion = `\n Datos de contacto:\n Correo: ${data.email} \n Celular: ${data.celular} \n Telefono: ${data.phone}`;
    solicitud.requestText = solicitud.requestText.toUpperCase()
    derecho.forEach(element => {
      if (solicitud.requestText.indexOf(element.value) !== -1) {
        solicitud.requesType = "Z06";
        return;
      }
    });

    const request = {
      tipoCliente: type,
      tipoIdentificacion: data.abreviatura,
      numeroIdentificacion: data.identificacion,
      primerNombrePersona: data.primerNombre.toUpperCase(),
      segundoNombrePersona: data.segundoNombre.toUpperCase(),
      apellidosPersona: data.primerApellido.toUpperCase(),
      correoElectronicoPersona: data.email,
      telefonoCelularPersona: data.celular,
      telefonoFijoPersona: data.phone,
      municipioResidenciaPersona: data.idMuniResidencia,
      generoPersona: data.genero,
      fechaNacimientoPersona: data.nacimiento,
      autenticado: data.autenticado,
      canal: "113",
      processingTypeCode: solicitud.requesType,
      serviceIssueCategoryID: solicitud.serviceIssueCategoryID,
      mainIncidentServiceIssueCategory:
        solicitud.mainIncidentServiceIssueCategory,
      mainObjectPartServiceIssueCategory:
        solicitud.mainObjectPartServiceIssueCategory,
      mainCauseServiceIssueCategory: solicitud.mainCauseServiceIssueCategory,
      organisationalCentreID: solicitud.site,
      text: `${solicitud.requestText} ${observacion}`,
      documentos: base64,
    };

    return request;
  }

  console.timeEnd("envio");
  try {
    console.time("peticion");
    console.log(requestOptions.body, "envio")

    const res = yield consumeServiceGenerico({ body: requestOptions.body, url: urlConfenalco })
    console.timeEnd("peticion");
    if (res) {
      if (
        res.data.responseMessageOut.header.responseStatus.statusCode ===
        "SUCESS"
      ) {
        var ticketID =
          res.data.responseMessageOut.body.response.createTicketResponse
            .ticket_id;
        yield ref.set({ comfenalcoTicket: ticketID }, { merge: true });
        yield put(reset("companyContactForm"));
        yield put(reset("peopleContactForm"));
        yield put(reloadPage({ reload: true }));
        yield put(
          contactoAdd({
            message: "Comfenalco Valle Delagente te confirma que la petición fue registrada de manera exitosa.  El número de radicado es " +
              ticketID +
              ", consérvalo para tu seguimiento a través de nuestra página web www.comfenalcovalle.com.co. Muchas gracias" +
              "\nImportante: Al validar su respuesta, por favor revise su bandeja de entrada, su bandeja Spam / Correos no deseados.",
            error: false,
          })
        );
      } else {
        var message = " ";
        if (res.data.responseMessageOut.body.response) {
          message = res.data.responseMessageOut.body.response.createTicketResponse.message;
        }
        console.error("respues servicio", res)
        yield ref.set(
          { ErrorGenerandoTicket: "FAILURE C4C : " + message },
          { merge: true }
        );
        yield put(reloadPage({ reload: true }));
        yield put(
          contactoAdd({
            message:
              "Lo sentimos. Comfenalco Valle Delagente te informa que la petición no pudo ser registrada en este momento. Por favor Inténtelo más tarde o escríbenos a servicioalcliente@comfenalcovalle.com.co. Muchas gracias",
            error: true,
          })
        );

      }
    }
  } catch (error) {
    console.error("Excetion: " + error.name + ": ** " + error);
    yield ref.set(
      {
        ErrorSistemaEnvioPeticion:
          "FAILURE peticion " + error.name + " : ** " + error,
      },
      { merge: true }
    );
    yield put(reloadPage({ reload: true }));
    yield put(
      contactoAdd({
        message:
          "Lo sentimos. Comfenalco Valle Delagente te informa que la petición no pudo ser registrada en este momento. Por favor Inténtelo más tarde o escríbenos a servicioalcliente@comfenalcovalle.com.co. Muchas gracias",
        error: true,
      })
    );
  }
}

function* consumeServiceGenerico(action) {
  try {
    const { body, url } = action;


    const request = { urlServicio: url, body: body }
    const headers = HEADERS_WS;

    const respues = yield call(axios.post, process.env.REACT_APP_FIREBASE_SEND_CONSUME_SERVICIOS_GENERICOS, request, headers);
    //console.log(JSON.stringify(respues.data))
    return respues;
  } catch (error) {
    console.error("Function.consumeServiceGenerico", error);
    throw `Function.consumeServiceGenerico - saga.js -${error}`;
  }


}
export function* uploadFile(action) {
  const {
    value: {
      event: { files },
      fileData,
    },
  } = action;

  const reader = new FileReader();
  const fileBytes = reader.readAsArrayBuffer(files[0]);

  const fireStorageRef = firebaseStorage.ref();
  const fileReference = fireStorageRef.child(
    `${fileData.formName}/${fileData.name}/${files[0].name}`
  );

  yield fileReference.put(files[0]);

  if (fileBytes) {
    yield put(uploadReducer());
  }
}

function ordenar(a, b) {
  if (a.label > b.label) {
    return 1;
  }
  if (a.label < b.label) {
    return -1;
  }
  return 0;
}
function* sedesHomologadas(action) {
  const { sede, sedeH } = action;
  var sedeF = [];
  sede.forEach(element => {
    var aux = {}
    var bandera = false;
    sedeH.forEach(element2 => {
      if (element.value === element2.value) {
        aux = element2;
        bandera = true;
        return;
      }
    });
    if (bandera === true) {
      sedeF.push(aux);
    } else {
      sedeF.push({
        value: element.value,
        label: "Sin Homologar"
      });
    }
  });
  return sedeF;
}

function* buildDynamicOptions(action) {
  const dynamicOptions = {};

  const sedes = yield getAsyncCatalog("SEDES");
  const sedesH = yield getAsyncCatalog("SEDES_HOMOLOGADAS");
  const sedesF = yield sedesHomologadas({ sede: sedes, sedeH: sedesH });
  const tipoDoc = yield getAsyncCatalog("TIPOSDOC");
  const tipoDocCompany = yield getAsyncCatalog("TIPOS_DOC_EMP");
  const departamentos = yield getAsyncCatalog("DEPARTAMENTO");
  const genero = yield getAsyncCatalog("GENERO");
  const related = yield getAsyncCatalog("SERVICIOS");
  const requesType = yield getAsyncCatalog("TIPO_SOLICITUD_CONTACTO");
  const companySize = yield getAsyncCatalog("TAMANIO_EMPRESA");
  const cargos = yield getAsyncCatalog("CARGOS");
  const cities = yield getAsyncCatalog("CITIES");
  const motivos = yield getAsyncCatalog("MOTIVOS");
  const derecho = yield getAsyncCatalog("DERECHO_PETICION");
  const indicativo = yield getAsyncCatalog("INDICATIVO_FIJOS");
  const activateIndicativo = yield getAsyncCollection("INDICATIVO_FIJOS");
  yield put(cargarCatalogos({ cities: cities.sort(ordenar), indicativo: indicativo, activateIn: activateIndicativo, docs: tipoDoc, derecho: derecho, depto: departamentos, motivos: motivos.sort(ordenar), sedes: sedesF.sort(ordenar) }));
  yield put(cargarCargo(cargos.sort(ordenar)));

  dynamicOptions.cargo = cargos.sort(ordenar);
  dynamicOptions.related = related.sort(ordenar);
  dynamicOptions.documentCompanyType = tipoDocCompany.sort(ordenar);
  dynamicOptions.abreviatura = tipoDoc.sort(ordenar);
  dynamicOptions.departamento = departamentos.sort(ordenar);
  dynamicOptions.companyState = departamentos.sort(ordenar);
  dynamicOptions.genero = genero.sort(ordenar);
  dynamicOptions.requesType = requesType.sort(ordenar);
  dynamicOptions.companySize = companySize.sort(ordenar);
  // dynamicOptions.goal = motivos.sort(ordenar);
  // dynamicOptions.site = sedesF.sort(ordenar);
  // var aux=[];
  // aux.push({data:motivos,name:"MOTIVOS"});
  // aux.push({data:related,name:"SERVICIOS"});
  // aux.push({data:tipoDoc,name:"TIPOSDOC"});
  // aux.push({data:tipoDocCompany,name:"TIPOS_DOC_EMP"});
  // aux.push({data:sedesH,name:"SEDES_HOMOLOGADAS"});
  // aux.push({data:genero,name:"GENERO"});
  // aux.push({data:requesType,name:"TIPO_SOLICITUD_CONTACTO"});
  // aux.push({data:companySize,name:"TAMANIO_EMPRESA"});
  //yield grabar(aux)
  yield put(loadCatalogSuccess(dynamicOptions));
 // yield initInfoSolicitud(action);
}

function* onReduxChange(action) {
  const catalogo = yield select(getCatalogosCargados);
  const motivos = catalogo.motivos;
  const ciudades = catalogo.cities;
  const sedes = catalogo.sedes;

  const {
    meta: { field, form },
    payload,
  } = action;
  var newData = {};
  var aux = field.split(".");
  var newDataCh = {};

  switch (aux[1]) {
    case "departamento":
      var citiesP = crearCities(ciudades, payload);
      newData = {
        disable: false,
        options: citiesP.sort(ordenar),
        field: "idMuniResidencia",
      };
      return yield put(loadDocumentSuccess(newData));

    case "related":
      var motiv = crearMotivos(motivos, payload);
      newData = {
        disable: false,
        options: motiv.sort(ordenar),
        field: "goal",
      };
      return yield put(loadDocumentSuccess(newData));

    case "companyState":
      var cities = crearCities(ciudades, payload);
      newData = {
        disable: false,
        options: cities.sort(ordenar),
        field: "companyTown",
      };
      return yield put(loadDocumentSuccess(newData));

    case "goal":
      var sede = crearSedes(sedes, payload);
      newData = {
        disable: false,
        options: sede.sort(ordenar),
        field: "site",
      };
      return yield put(loadDocumentSuccess(newData));

    default:
      return;
  }
}

function crearCities(citys, idDepto) {
  var arrCitys = [];
  citys.forEach((element) => {
    if (element.id_depa === idDepto) {
      arrCitys.push({
        label: element.label,
        value: element.value,
      });
    }
  });
  return arrCitys;
}

function crearSedes(sedes, payload) {
  var auxSede = payload.split(":");
  var lisSede = auxSede[1].split(",");
  var arrSedes = [];
  lisSede.forEach((lisSedes) => {
    sedes.forEach((element) => {
      if (lisSedes === element.value) {
        arrSedes.push({
          label: element.label,
          value: element.value,
        });
      }
    });
  });

  return arrSedes;
}

function crearMotivos(motivos, idServicio) {
  var arrMotivos = [];
  motivos.forEach((element) => {
    if (element.idTextoServicio === idServicio) {
      arrMotivos.push({
        label: element.textoMotivo,
        value:
          element.mainCauseServiceIssueCategory +
          ";" +
          element.mainIncidentServiceIssueCategory +
          ";" +
          element.mainObjectPartServiceIssueCategory +
          ";" +
          element.serviceIssueCategoryID +
          ":" +
          element.sede,
      });
    }
  });
  return arrMotivos;
}
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

function* cargaArchivo(action) {
  const { value: { event: { files } } } = action;
  var base = yield select(getbase64Add)
  var ban = {};
  var bandera = false;
  if (files.length > 0) {
    if (base) {
      base.forEach(element => {
        if (element.name === files[files.length - 1].lastModified) {
          bandera = true;
          return;
        }
      });
    }
    if (bandera === false) {
      if (base.length > 3) {
        yield put(reloadPage({ reload: true }));
        yield put(
          contactoAdd({
            message: "El maximo de Adjuntos es 5, para adjuntar uno nuevo debe eliminar un actual",
            error: true,
          })
        );
        yield put(callDisabled(true))
      }
      const res = yield getBase64(files[files.length - 1]);

      var aux = res.split(",");
      var mime = files[files.length - 1].type;
      var name = files[files.length - 1].name;
      ban = {
        name: files[files.length - 1].lastModified, doc: {
          base64Archivo: aux[1],
          MIMECode: mime,
          filename: name,
        }
      }
      yield put(base64Add(ban))
    }
  }
}
function* deleteArchivo(action) {
  const { value: { event: { file }, fileData } } = action;
  var base = yield select(getbase64Add)
  var temp = []
  if (base) {
    base.forEach(element => {
      if (element.name === file.lastModified) {
      } else {
        temp.push(element);
      }
    });
    yield put(base64Remove(temp))
    yield put(callDisabled(false))
  }
}
function validateIndicativo(action) {
  const { number, catalogo } = action;
  var ban = false;
  if (emptyObject(number).length === 10) {
    const temp = number.substring(0, 3);
    catalogo.forEach(element => {
      if (element.value === temp) {
        ban = true;
        return;
      }
    });
  }
  return ban;
}

function* validateForm(action) {
  var valid = true;
  const { body, type } = action;
  const requiredCompany = REQUIRED_COMPANY;
  const requiredPersonal = REQUIRED_PERSONAL;
  const requiredPersonalCompany = REQUIRED_PERSONAL_COMPANY;
  const requiredSolicitud = REQUIRED_SOLICITUD;
  const catalog = yield select(getCatalogosCargados)
  var banIndi = false;
  var messageIndicativo = false;
  const formValid = yield select(getValidForm);
  if (!formValid) {
    valid = false;
  }
  if (catalog.activateIn?.ACTIVATE) {
    banIndi = true;
  }
  const validador = yield select(getConsultaValidador);
  if (type === "people") {
    if (validador.data?.abreviatura?.length > 0) {
      if (body?.informacionpersonal) {
        if (validador.faltan.length > 0) {
          validador.faltan.forEach(element => {
            if (!body.informacionpersonal[element]) {
              valid = false;
            }
          });
        }
      } else {
        valid = false;
      }
    } else {
      if (body?.informacionpersonal) {
        requiredPersonal.forEach(element => {
          if (!body.informacionpersonal[element]) {
            valid = false;
          }
        });

        if (banIndi && emptyObject(body?.informacionpersonal?.phone).length > 0) {

          const res = validateIndicativo({ number: body?.informacionpersonal?.phone, catalogo: catalog.indicativo })
          if (!res) {
            valid = false;
            if (!messageIndicativo) {
              messageIndicativo = `El indicativo ingresado en el campo "Telefono fijo" es incorrecto `;
            } else {
              messageIndicativo = `${messageIndicativo}, el indicativo ingresado en el campo "Telefono fijo" es incorrecto `;
            }

          }
        }

      } else {
        valid = false;
      }
    }

    if (body?.informacionsolicitud) {
      requiredSolicitud.forEach(element => {
        if (!body.informacionsolicitud[element]) {
          valid = false;
        }
      });
    } else {
      valid = false;
    }
  } else {
    if (body?.companydataform) {
      requiredCompany.forEach(element => {
        if (!body.companydataform[element]) {
          valid = false;
        }
      });
      if (banIndi && emptyObject(body?.companydataform?.companyPhone).length > 0) {
        const res = validateIndicativo({ number: body?.companydataform?.companyPhone, catalogo: catalog.indicativo })
        if (!res) {
          valid = false;
          if (!messageIndicativo) {
            messageIndicativo = `El indicativo ingresado en el campo "Telefono fijo" de los datos de la empresa es incorrecto `;
          } else {
            messageIndicativo = `${messageIndicativo}, el indicativo ingresado en el campo "Telefono fijo" de los datos de la empresa es incorrecto `;
          }

        }
      }
    } else {
      valid = false;
    }
    if (body?.informacionpersonalcompany) {
      requiredPersonalCompany.forEach(element => {
        if (!body.informacionpersonalcompany[element]) {
          valid = false;
        }
      });

      if (banIndi && emptyObject(body?.informacionpersonalcompany?.phone).length > 0) {
        const res = validateIndicativo({ number: body?.informacionpersonalcompany?.phone, catalogo: catalog.indicativo })
        if (!res) {
          valid = false;
          if (!messageIndicativo) {
            messageIndicativo = `El indicativo ingresado en el campo "Telefono fijo" de los datos de contacto es incorrecto `;
          } else {
            messageIndicativo = `${messageIndicativo}, el indicativo ingresado en el campo "Telefono fijo" de los datos de constacto es incorrecto `;
          }

        }
      }
    } else {
      valid = false;
    }
    if (body?.companyrequestform) {
      requiredSolicitud.forEach(element => {
        if (!body.companyrequestform[element]) {
          valid = false;
        }
      });
    } else {
      valid = false;
    }
  }
  return { valid, messageIndicativo };
}

function* selectedTab(action) {
  const data = yield select(getDataDisable);

  if (action.value.fieldValue === "company") {
    yield put(callDinamicDisable({ data: data, state: false }))
  } else {
    yield put(callDinamicDisable({ data: data, state: true }))
  }
}

function* reduxBlurFuction(action) {
  const { meta: { form, field }, payload } = action;
  if (form === "peopleContactForm" && field === "informacionpersonal.abreviatura") {
    if (payload.length > 0) {
      yield put(saveConsultaValidador({ field: "tipo", data: payload }))
      yield put(change("peopleContactForm", "informacionpersonal.identificacion", ""));
      yield put(disablePeopleForm({ field: "identificacion", state: false, todos: false }))
    }
  }
  if (form === "peopleContactForm" && field === "informacionpersonal.identificacion") {
    if (payload.length > 0) {
      var id = payload;
      const getValidador = yield select(getConsultaValidador);
      const data = yield consumeValidador({ id, tipo: getValidador.tipo });
    }
  }
}

function* respuestaValidador(action) {
  const dataSelected = yield select(getDataSelected);
  if (action) {

    var depto = '';
    const ciry = emptyObject(action.afiliado.idMuniResidencia);
    const catalogo = yield select(getCatalogosCargados);
    catalogo.cities.forEach(element => {
      if (element.value === ciry) {
        depto = element.id_depa;
        return;
      }
    });

    var cities = crearCities(catalogo.cities, depto);
    var newData = {
      disable: false,
      options: cities.sort(ordenar),
      field: "idMuniResidencia",
    };
    yield put(loadDocumentSuccess(newData));

    catalogo.depto.forEach(element => {
      if (element.value === depto) {
        depto = element;
        return;
      }
    });
    var tipoDoc = emptyObject(action.afiliado.abreviatura);
    catalogo.docs.forEach(element => {
      if (element.value === tipoDoc) {
        tipoDoc = element.label;
        return;
      }
    });

    var phone = ''
    if (emptyObject(action?.afiliado?.telefonoResidencia).length > 0) {
      phone = action?.afiliado?.telefonoResidencia;
    }
    const auth0Data1 = {
      abreviatura: emptyObject(action.afiliado.abreviatura),
      identificacion: emptyObject(action.afiliado.identificacion),
      primerNombre: emptyObject(action.afiliado.primerNombre),
      segundoNombre: emptyObject(action.afiliado.segundoNombre),
      primerApellido: emptyObject(action.afiliado.primerApellido) + " " + emptyObject(action.afiliado.segundoApellido),
      genero: emptyObject(action.afiliado.sexo),
      email: emptyObject(action?.afiliado?.email),
      celular: emptyObject(action?.afiliado?.celular),
      departamento: depto.value,
      idMuniResidencia: ciry,
      nacimiento: emptyObject(action.afiliado.fechaNacimiento),
      phone: phone,
    }
    yield put(saveConsultaValidador({ field: "data", data: auth0Data1 }));

    const requiredPersonal = [
      "abreviatura",
      "identificacion",
      "primerNombre",
      "primerApellido",
      "genero",
      "email",
      "celular",
      "departamento",
      "idMuniResidencia",
      "nacimiento",
    ];

    var disable = dataSelected ? MOSTRAR_CAMPOS_FORM_VAlIDADOR_SELECTED : MOSTRAR_CAMPOS_FORM_VAlIDADOR;
    const faltan = [];
    // for (var item of MOSTRAR_CAMPOS_FORM_LISTA) {
    //   if (auth0Data1[item]) {
    //     disable[item] = true;
    //   }
    // }
    requiredPersonal.forEach(item => {
      if (!auth0Data1[item]) {
        faltan.push(item);
      }
    });
    yield put(saveConsultaValidador({ field: "faltan", data: faltan }));
    var dataPeople = PEOPLE_FORM;
    dataPeople.forEach(element => {
      if (auth0Data1[element.name]) {
        element.type = "text";
        element.typeForm = "text";
        if (element.name === "email") {
          element.name = "email2";
        }
      }
    });

    const peopleContactForm = (handleUpload, handleRemove, Hclear) => {
      const PEOPLE_CONTACT_FORM = [

        {
          label: "Tus datos personales",
          name: "informacionpersonal",
          placeholder: "Diligencia los campos tipo de documento y documento de identificación para validar la información suministrada.",
          type: "section",
          typeForm: "section",
          className: "rs-100",
          fields: dataPeople,
        },
        {
          label: "Información de tu solicitud",
          name: "informacionsolicitud",
          type: "section",
          typeForm: "section",
          className: "rs-100",
          fields: generatePeopleSubForm(handleUpload, handleRemove, Hclear)
        },
      ];

      return PEOPLE_CONTACT_FORM;
    }
    const auth0Data = {
      abreviatura: tipoDoc,
      identificacion: emptyObject(action.afiliado.identificacion),
      primerNombre: emptyObject(action.afiliado.primerNombre),
      segundoNombre: emptyObject(action.afiliado.segundoNombre),
      primerApellido: emptyObject(action.afiliado.primerApellido) + " " + emptyObject(action.afiliado.segundoApellido),
      genero: enmascararCadena({ cadena: emptyObject(action.afiliado?.sexo === "F" ? "Femenino" : action.afiliado?.sexo === "M" ? "Masculino" : "No especificado"), type: "general" }),
      email2: enmascararCadena({ cadena: emptyObject(action?.afiliado?.email), type: "email" }),
      celular: enmascararCadena({ cadena: emptyObject(action?.afiliado?.celular), type: "cel" }),
      departamento: enmascararCadena({ cadena: emptyObject(depto.label), type: "general" }),
      idMuniResidencia: enmascararCadena({ cadena: emptyObject(action.afiliado.municipioResidencia), type: "general" }),
      nacimiento: enmascararCadena({ cadena: emptyObject(action.afiliado.fechaNacimiento), type: "date" }),
      phone: enmascararCadena({ cadena: emptyObject(phone), type: "phone" })
    }
    const data = {
      informacionpersonal: auth0Data,
    }
    yield put(changeType(peopleContactForm))

    yield put(disablePeopleForm({ data: disable, todos: true }));

    yield put(initialize("peopleContactForm", data));
  } else {
    yield put(reloadPage({ reload: true }));
    var disable = dataSelected ? MOSTRAR_CAMPOS_FORM_SELECTED : MOSTRAR_CAMPOS_FORM;
    yield put(
      contactoAdd({
        message: "Su número y tipo de identificación No arrojan resultados. Verifique los datos o continue diligenciando el formulario",
        error: true,
      }));
    yield put(disablePeopleForm({ data: disable, todos: true }))
  }
}
function enmascararCadena(action) {
  const { cadena, type } = action;
  if (cadena.length > 0) {
    const temp = cadena.split('');
    var res = '';
    if (type === "email") {
      var con = 0;
      temp.forEach(element => {
        if (con < 3) {
          res = res + element;
        } else {
          if (element === "@") {
            con = -1;
            res = res + element;
          } else {
            if (element === ".") {
              res = res + element;
            } else {
              res = res + '*';
            }
          }
        }

        con += 1;
      });
    }
    if (type === "cel") {
      var con = 0;
      var lim = temp.length - 3;
      temp.forEach(element => {
        if (con < 3) {
          res = res + element;
        } else {
          if (con >= lim) {
            res = res + element;
          } else {
            res = res + '*';
          }
        }

        con += 1;
      });
    }
    if (type === "phone") {
      var con = 0;
      var lim = temp.length - 3;
      temp.forEach(element => {
        if (con >= lim) {
          res = res + element;
        } else {
          res = res + '*';
        }
        con += 1;
      });
    }
    if (type === "date") {
      temp.forEach(element => {
        if (element === "-") {
          res = res + element;
        } else {
          res = res + '*';
        }
      });
    }
    if (type === "general") {
      temp.forEach(element => {
        res = res + '*';
      });
    }
  }
  return res;
}
function* consumeValidador(action) {
  const { id, tipo } = action;
  const messageid = tipo + id;
  var date = "";
  var date = new Date();

  const urlConfenalco = process.env.REACT_APP_URL_COMFENALCO_ENDPOINT;
  const headers = HEADERS_WS;
  const body = {

    "requestMessageOut": {
      "header": {
        "systemId": "FORMULARIOSWEB",
        "invokerDateTime": date,
        "destination": {
          "namespace": "http://co/com/comfenalcovalle/esb/ws/PksiryacValidarServicios",
          "name": "ValidadorServiciosCaja",
          "operation": "execute"
        },
        "messageId": tipo + "|" + id,
        "securityCredential": {
          "userToken": "",
          "userName": ""
        },
        "moduleId": "VALIDADOR",
        "logginData": {
          "sourceSystemId": "",
          "destinationSystemId": ""
        },
        "classification": {
          "classification": ""
        }
      },
      "body": {
        "request": {
          "validadorRequest": {
            "idPers": "-1",
            "abreviatura": tipo,
            "identificacion": id,
          }
        }
      }
    }

  }

  var resul = false;
  try {

    const data = [];
    const res = yield consumeServiceGenerico({ body: body, url: urlConfenalco })
    if (res.data.responseMessageOut.header.responseStatus.statusCode === "SUCESS") {
      resul = res.data.responseMessageOut.body.response.validadorResponse;

    }
    yield respuestaValidador(resul);

  } catch (error) {
    console.error(error, "error")
    return error;
  }
}
function* initInfoSolicitud(action) {
  console.log("initInfoSolicitud", action);
  const { value } = action;
  try {

    const { goal, related, requesType, site } = value;

    const catalogo = yield select(getCatalogosCargados);
    const motivos = catalogo.motivos;
    const sedes = catalogo.sedes;

    const infoSolicitud = {
      informacionsolicitud: {
        related: `${emptyObject(related)}SCF`,
        requesType: emptyObject(requesType),
        goal: emptyObject("CCPR9;CC;CCTCR;CO-SC:CONCEN"),
        site: emptyObject(site),
      }
    }

    let newData = {};
    var motiv = yield crearMotivos(motivos, `${emptyObject(related)}SCF`);
    newData = {
      disable: false,
      options: motiv.sort(ordenar),
      field: "goal",
    };
    yield put(loadDocumentSuccess(newData));

    var sede = yield crearSedes(sedes, "CCPR9;CC;CCTCR;CO-SC:CONCEN");
    newData = {
      disable: false,
      options: sede.sort(ordenar),
      field: "site",
    };
    yield put(loadDocumentSuccess(newData));

    // yield put(change("peopleContactForm", "informacionsolicitud.requesType", requesType));
    // yield sleep(1000)
    // yield put(change("peopleContactForm", "informacionsolicitud.related", related));
    // yield sleep(1000)
    // yield put(change("peopleContactForm", "informacionsolicitud.goal", infoSolicitud.informacionsolicitud.goal));
    yield sleep(1000)
    console.log("infoSolicitud", infoSolicitud)
    const res = yield put(initialize("peopleContactForm", infoSolicitud));
    yield put(dataMotivoSelected(true));
  } catch (error) {
    console.error(error, "error")
    return error;
  }
}


export function* watchUsers() {
  yield takeLatest(SIGN_USER, signUser);
  yield takeLatest(INIT_USER_DATA, inituserData);
  yield takeLatest(CREATE_COMFE_TICKET, createTicket);
  yield takeLatest(LOAD_CATALOG, buildDynamicOptions);
  yield takeLatest(REDUX_CHANGE, onReduxChange);
  yield takeLatest(BASE_64, enviarTicket);
  yield takeLatest(SELECT_FORM_FIELD, llenarForm);
  yield takeLatest(UPLOAD_FILE, cargaArchivo);
  yield takeLatest(REMOVE_FILE, deleteArchivo);
  yield takeLatest(SELECT_FORM_FIELD, selectedTab);
  yield takeLatest(REDUX_BLUR, reduxBlurFuction);
  // yield takeLatest(INIT_INFO_SOLICITUD, initInfoSolicitud);
}
